import request from '@/utils/request'

// 添加节点
export function addIotNode(data) {
    return request({
      url: '/iot/node/add',
      method: 'post',
      data: data
    })
}

// 获取节点
export function getIotNode(id) {
    return request({
      url: '/iot/node/get?id=' + id,
      method: 'get'  
    })
}

// 更新节点
export function updateIotNode(data) {
    return request({
      url: '/iot/node/update',
      method: 'post',
      data: data
    })
  }
  
// 删除节点
export function delIotNode(id) {
    return request({
      url: '/iot/node/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 节点列表
export function listIotNode(query) {
  return request({
    url: '/iot/node/list',
    method: 'get',
    params: query
  })
}