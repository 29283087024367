<template>
  <div class="app-container">
    <el-row>
      <el-col :span="12" class="card-box">
        <el-card>
          <div slot="header"><span>{{ node.node_name }}</span></div>
          <div class="el-table el-table--enable-row-hover el-table--medium">
            <table cellspacing="0" style="width: 100%;">              
              <tbody>
                <tr>
                  <td><div class="cell">分类</div></td>
                  <td><div class="cell" v-if="node.version">{{ node.version }}</div></td>
                  <td/>
                </tr>
                <tr>
                  <td><div class="cell">运行时间</div></td>
                  <td><div class="cell" v-if="node.run_time">{{ node.run_time }}</div><div class="cell" v-else>无</div></td>
                  <td/>
                </tr>
                <tr>
                  <td><div class="cell">状态</div></td>
                  <td><div class="cell" v-if="node.sys_status==1">使用中</div><div class="cell" v-else>停用</div></td>
                  <td/>
                </tr>
                <tr>
                  <td><div class="cell">地址</div></td>
                  <td><div class="cell" v-if="node.ip_address">{{ node.ip_address }}</div></td>
                  <td/>
                </tr>                
                <tr>
                  <td><div class="cell">说明</div></td>
                  <td><div class="cell" v-if="node.remark">{{ node.remark }}</div><div class="cell" v-else>无</div></td>
                  <td/>
                </tr>
                <tr>
                  <td><div class="cell">产品数量</div></td>
                  <td><div class="cell" v-if="node_more.productCount">{{ node_more.productCount }}</div><div class="cell" v-else>无</div></td>
                  <td><router-link  :to="{path:'/iot/product'}" class="link-type cell" >
                          <span>>>更多详情</span>
                      </router-link>
                  </td>
                </tr>
                <tr>
                  <td><div class="cell">模型数量</div></td>
                  <td><div class="cell" v-if="node_more.mouldCount">{{ node_more.mouldCount }}</div><div class="cell" v-else>无</div></td>
                  <td><router-link  :to="{path:'/iot/mould'}" class="link-type cell" >
                          <span>>>更多详情</span>
                      </router-link>
                  </td>
                </tr>
                <tr>
                  <td><div class="cell">设备数量</div></td>
                  <td><div class="cell" v-if="node_more.clientCount">{{ node_more.clientCount }}</div><div class="cell" v-else>无</div></td>
                  <td/>
                </tr>
              </tbody>
            </table>
          </div>
        </el-card>
      </el-col>

      <el-col :span="12" class="card-box">
        <el-card>
          <div slot="header"><span>数据</span></div>
          <div class="el-table el-table--enable-row-hover el-table--medium">
            <table cellspacing="0" style="width: 100%;">              
              <tbody>
                <tr>
                  <td><div class="cell">连接数</div></td>
                  <td><div class="cell" >{{ node_more.clientConect }}</div></td>                  
                </tr>
                <tr>
                  <td><div class="cell">订阅数</div></td>
                  <td><div class="cell" >{{ node_more.subscriptionsTotal}}</div></td>                  
                </tr>
                <tr>
                  <td><div class="cell">当前订阅数</div></td>
                  <td><div class="cell" >{{ node_more.subscriptionsCurrent }}</div></td>                  
                </tr>
                <tr>
                  <td><div class="cell">会话数</div></td>
                  <td><div class="cell"  >{{ node_more.clientData }}</div></td>
                </tr>
                <tr>
                  <td><div class="cell">发包数</div></td>
                  <td><div class="cell"  >{{ node_more.packetsSendNums }}</div></td>
                </tr>
                <tr>
                  <td><div class="cell">发包数据量</div></td>
                  <td><div class="cell" >{{ node_more.packetsSendBytes }} Bytes</div></td>
                </tr>
                <tr>
                  <td><div class="cell">接收数</div></td>
                  <td><div class="cell"  >{{ node_more.packetsReceivedNums }}</div></td>
                </tr>
                <tr>
                  <td><div class="cell">接收数据量</div></td>
                  <td><div class="cell"  >{{ node_more.packetsReceivedTytes }} Bytes</div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-card>
      </el-col>      

     <el-col :span="24" class="card-box">
        <el-card>
          <div slot="header">
            <router-link  :to="'/iot/protocol'" class="link-type" >
                <span>协议产品 >>更多详情</span>
            </router-link>
          </div>
          <div class="el-table el-table--enable-row-hover el-table--medium">
            <table cellspacing="0" style="width: 100%;">
              <thead>
                <tr>
                  <th class="is-leaf"><div class="cell">协议</div></th>
                  <th class="is-leaf"><div class="cell">产品数量</div></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in protolist" :key="item" >
                  <td><div class="cell">{{item.protoName}}</div></td>
                  <td><div class="cell">{{item.count}}</div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-card>
      </el-col>

      <el-col :span="24" class="card-box">
        <el-card>
          <div slot="header">            
            <router-link  :to="'/iot/device'" class="link-type" >
                <span>在线设备 >>更多详情</span>
            </router-link>
          </div>
          <div class="el-table el-table--enable-row-hover el-table--medium">                        
              <vxe-grid
                      resizable
                      ref="xGrid"
                      stripe
                      show-overflow
                      highlight-hover-row                                           
                      :columns="tableColumn"
                      :data="onlinedeviceList">
                <!--自定义空数据模板-->
                <template #empty>
                      <span >
                        <p>暂无数据</p>
                      </span>
                </template>

              </vxe-grid>
              <vxe-pager
                      background
                      size="small"                     
                      :current-page="queryParams.pageNum"
                      :page-size="queryParams.pageSize"
                      :total="total"
                      @page-change="handleSizeChange">
              </vxe-pager>            
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getIotNode} from "@/api/iot/node";
export default {
  name: "NodeInfo",
  data() {
    return {
      // 加载层信息
      total: 0,
      nodeid:0,
      
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,       
      },
      loading: [],    
      node_more:[],     
      node: [], 
      onlinedeviceList: [],
      protolist:[],      
       // 列
      tableColumn: [       
        {field: 'id', title: '编号', width: 60, fixed: "left"},        
        {field: 'device_name', title: '名称' },
        {field: 'ip_address', title: 'IP地址' },
        {field: 'client_id', title: 'ClientID' },
        {field: 'keep_alive', title: 'KeepAlive', width: 100 },
        {field: 'session_expiry', title: 'Session时效' },
        {field: 'version', title: '分类', width: 60},
        {field: 'conn_status', title: '状态' , width: 60,formatter:this.TopicFormat },
        {field: 'connect_time', title: '连接时间' ,formatter:this.ConnectFormat},  
        {field: 'disconnect_time', title: '离线时间' ,formatter:this.DisconnectFormat}
      ],
    };
  },
  created() {
    this.nodeid = this.$route.params && this.$route.params.id;      
    this.getList(this.nodeid);
    this.openLoading();
  },
  methods: {   
    getList(id) {
      getIotNode(id).then(response => {
        this.node = response.data;  
        this.node_more=   response.data.node_more; 
        this.protolist= response.data.protoList;
        this.onlinedeviceList=response.data.onlineDeviceList;
        this.total = response.data.node_more.onlineClient;
        this.loading.close();
      }).catch(function (error){
          console.log(error);
        });  
    },   
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getList(this.nodeid);
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },    
    TopicFormat({row}){
      return "在线";
    },
    ConnectFormat({row}){
      return this.timestampToTime(row.connect_time);
    },
    DisconnectFormat({row}){
      return this.timestampToTime(row.disconnect_time);
    },
    timestampToTime (cjsj) {
        var date = new Date(cjsj*1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y+M+D+h+m+s        
    },
    // 打开加载层
    openLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "获取中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    }
  }
};
</script>